<script>
import { Line } from 'vue-chartjs'
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips'
import { getStyle } from '@coreui/coreui/dist/js/coreui-utilities'

export default {
  extends: Line,
  props: ['height', 'width', 'dataTrainings', 'testingName'],
  data: function () {
    return {
      datasetsApi: [
        {
          label: 'Anottation Only',
          backgroundColor: 'rgba(255,100,255,.2)',
          borderColor: 'transparent',
          data: [],
          labels: []
        },
        {
          label: 'Not Anottation Only',
          backgroundColor: 'rgba(255,255,255,.3)',
          borderColor: 'transparent',
          data: [],
          labels: []
        }
      ],
      dataTrainings2: [],
      trainingSelected: {},
      resultTestingObject: {}
    }
  },
  mounted() {
    let labelTemp = []
    for (var i = 0; i < this.dataTrainings.length; i++) {
      let resultTestingObject = JSON.parse(this.dataTrainings[i].result_testing)
      let fieldTestingName = this.testingName
      if (resultTestingObject[fieldTestingName]) {
        if (resultTestingObject[fieldTestingName].notOnlyAnnotation && resultTestingObject[fieldTestingName].onlyAnnotation) {
          if (resultTestingObject[fieldTestingName].onlyAnnotation.rekap.ketepatan < 100 || resultTestingObject[fieldTestingName].notOnlyAnnotation.rekap.ketepatan < 100) {
            this.datasetsApi[0].data.push(resultTestingObject[fieldTestingName].onlyAnnotation.rekap.ketepatan)

            this.datasetsApi[1].data.push(resultTestingObject[fieldTestingName].notOnlyAnnotation.rekap.ketepatan)
            labelTemp.push(this.dataTrainings[i].name)
          }
        }
      }
    }

    this.renderChart(
      {
        labels: labelTemp,
        datasets: this.datasetsApi
      },
      {
        tooltips: {
          enabled: false,
          custom: CustomTooltips
        },
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                color: 'transparent',
                zeroLineColor: 'transparent'
              },
              ticks: {
                fontSize: 2,
                fontColor: 'transparent'
              }
            }
          ],
          yAxes: [
            {
              display: false,
              ticks: {
                display: false,
                min: Math.min.apply(Math, this.datasetsApi[0].data) - 5,
                max: Math.max.apply(Math, this.datasetsApi[0].data) + 5
              }
            }
          ]
        },
        elements: {
          line: {
            borderWidth: 1
          },
          point: {
            radius: 4,
            hitRadius: 10,
            hoverRadius: 4
          }
        }
      }
    )

    //});
  }
}
</script>

